import styles from './right-form.module.scss';
import classNames from 'classnames';
import { useState } from 'react';

import {
    EReCaptchaV2Size,
    EReCaptchaV2Theme,
    ReCaptchaProvider,
    ReCaptchaV2,
} from 'react-recaptcha-x';

export interface RightFormProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const RightForm = ({ className }: RightFormProps) => {
    const [contactEmail, setContactEmail] = useState('');
    const [summary, setSummary] = useState('');
    const [details, setDetails] = useState('');

    const [notBot, setNotBot] = useState(false);

    const [submitClicked, setSubmitClicked] = useState(false);

    function v2CallbackA(token: string | false | Error): void {
        // if (typeof token === "string") {
        //   this.setState({
        //     v2TokenA: token,
        //     v2ExpiredA: false,
        //     v2ErrorA: false
        //   });
        // } else if (typeof token === "boolean" && !token) {
        //   this.setState({
        //     v2ExpiredA: true
        //   });
        // } else if (token instanceof Error) {
        //   this.setState({
        //     v2ErrorA: true
        //   });
        // }
        // console.log('v2CallbackA', token);

        if (typeof token === 'string') {
            setNotBot(true);
        } else if (typeof token === 'boolean' && !token) {
            setNotBot(false);
        } else if (token instanceof Error) {
            setNotBot(false);
        }
    }

    // const userTyped = contactEmail || summary || details;

    function isEmail(email: string): boolean {
        var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (email !== '' && email.match(emailFormat)) {
            return true;
        }
        return false;
    }

    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.RightForm}>
                <form className={styles.RightFormDiv}>
                    <label className={styles.FormSubmitLabel}>联络EMAIL</label>
                    <input
                        type="email"
                        placeholder="sample@sample.com"
                        className={styles.FormSubmitCol2}
                        id="email"
                        autoComplete="email"
                        onChange={(e) => {
                            e.preventDefault();
                            // console.dir(e.currentTarget.value);
                            setContactEmail(e.currentTarget.value);
                        }}
                    />
                    {isEmail(contactEmail) ? null : (
                        <span className={styles.FormEmailNote}>Email地址好像不对哦</span>
                    )}

                    <br />
                    <br />
                    <label className={styles.FormSubmitLabel}>简短概述</label>
                    <input
                        type="text"
                        placeholder="关于这个作业的概述"
                        className={styles.FormSubmitCol2}
                        id="summary"
                        onChange={(e) => {
                            e.preventDefault();
                            // console.dir(e.currentTarget.value);
                            setSummary(e.currentTarget.value);
                        }}
                    />
                    <br />
                    <br />
                    <label className={styles.FormSubmitLabel}>作业细节</label>
                    <textarea
                        placeholder="在这里可以提供作业的细节 "
                        rows={5}
                        className={styles.FormSubmitCol2}
                        id="details"
                        onChange={(e) => {
                            e.preventDefault();
                            // console.dir(e.currentTarget.value);
                            setDetails(e.currentTarget.value);
                        }}
                    />
                    <br />
                    <br />

                    <ReCaptchaProvider siteKeyV2="6Le_L60mAAAAAI5z80Xo9Kiojhj7munxgFFxyQSy">
                        <ReCaptchaV2
                            id="my-id"
                            className="test"
                            callback={v2CallbackA}
                            data-test-id="my-test-id"
                            theme={EReCaptchaV2Theme.Light}
                            size={EReCaptchaV2Size.Normal}
                        />
                    </ReCaptchaProvider>

                    <br />
                    <div hidden={!notBot}>
                        <button
                            className={styles.FormButtonClick}
                            // disabled={Boolean(notBot && userTyped)}
                            onClick={async (e) => {
                                e.preventDefault();
                                // console.log('button clicked');

                                // TWO ways of invoking:
                                //    NOT sure if the gcloud function needs: allUsers for function invoker:
                                //    see https://github.com/firebase/functions-samples/issues/395
                                // invoke via firebase SDK library,
                                //      which is a wrapper around http request

                                // const functions = getFunctions();
                                // const helloworld = httpsCallable(functions, 'helloworld');
                                // helloworld({ aaaa: '11111' });

                                // invoke via a raw http request
                                const sendEmailURL =
                                    process.env.NODE_ENV === 'development'
                                        ? 'http://127.0.0.1:5001/xiezuoye-1p/us-central1/helloWorld'
                                        : 'https://helloworld-55ew5izmla-uc.a.run.app';

                                // To use fetch:
                                // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
                                fetch(sendEmailURL, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({ contactEmail, summary, details }),
                                })
                                    .then((response) => {
                                        // Do something here on success!
                                        console.log(' I fetched ');
                                        setSubmitClicked(true);
                                    })
                                    .catch((error) => {
                                        // Do something here on error!
                                        console.log(' I fetched but failed ');
                                    });
                            }}
                        >
                            免费咨询
                        </button>
                    </div>

                    <div hidden={!submitClicked}>
                        <span className={styles.FormSubmitConfirm}>
                            好的收到，即将达到战场， 请稍后查阅邮箱哦！
                        </span>
                    </div>

                    {/* <div>
                        <input
                            type="submit"
                            value="免费咨询"
                            disabled
                            // onSubmit={(e) => {
                            //     e.preventDefault();
                            //     console.log('submit clicked');
                            // }}
                            onClick={async (e) => {
                                e.preventDefault();
                                console.log('submit clicked');

                                // TWO ways of invoking:
                                //    NOT sure if the gcloud function needs: allUsers for function invoker:
                                //    see https://github.com/firebase/functions-samples/issues/395
                                // invoke via firebase SDK library,
                                //      which is a wrapper around http request

                                // const functions = getFunctions();
                                // const helloworld = httpsCallable(functions, 'helloworld');
                                // helloworld({ aaaa: '11111' });

                                // invoke via a raw http request
                                const sendEmailURL =
                                    process.env.NODE_ENV === 'development'
                                        ? 'http://127.0.0.1:5001/xiezuoye-1p/us-central1/helloWorld'
                                        : 'https://helloworld-55ew5izmla-uc.a.run.app';

                                // To use fetch:
                                // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
                                fetch(sendEmailURL, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({ contactEmail, summary, details }),
                                })
                                    .then((response) => {
                                        // Do something here on success!
                                        console.log(' I fetched ');
                                    })
                                    .catch((error) => {
                                        // Do something here on error!
                                        console.log(' I fetched but failed ');
                                    });
                            }}
                            // onSubmit={(e) => {
                            //     e.preventDefault();
                            //     console.log('submit clicked');
                            // }}
                            className={styles.RightFormSubmit}
                        />
                    </div> */}
                </form>

                {/* <p>{JSON.stringify([contactEmail, summary, details, notBot])}</p>
                <p>{JSON.stringify([process.env.REACT_APP_API_KEY])}</p>
                <p>{JSON.stringify([process.env.SENDGRID_API_KEY])}</p>
                <p>{JSON.stringify([process.env.NODE_ENV])}</p>
                <p>{JSON.stringify([process.env.FUNCTIONS_EMULATOR])}</p> */}
            </div>
        </div>
    );
};
