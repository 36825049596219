import styles from './App.module.scss';
import { TextBlock } from './components/text-block/text-block';
import { RightForm } from './components/right-form/right-form';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyBDc5I6pL57EmNEePeTM5zJZWeFUhLfzZw',
    authDomain: 'xiezuoye-1p.firebaseapp.com',
    projectId: 'xiezuoye-1p',
    storageBucket: 'xiezuoye-1p.appspot.com',
    messagingSenderId: '1041256335858',
    appId: '1:1041256335858:web:3524f3234acc1c021f836b',
    measurementId: 'G-HP3BT0J3VR',
};

// Initialize Firebase
getAnalytics(initializeApp(firebaseConfig));
// const analytics = getAnalytics(app);

function App() {
    return (
        <div className={styles.App}>
            <div className={styles.MainRow}>
                <TextBlock className={styles.LeftDemo} />
                <RightForm className={styles.RightFormDiv} />
            </div>
            <div className={styles.BottemDiv}>All Rights Reserved</div>
        </div>
    );
}

export default App;
