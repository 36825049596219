import styles from './text-block.module.scss';
import classNames from 'classnames';
import App_module from '../../App.module.scss';

export interface TextBlockProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TextBlock = ({ className }: TextBlockProps) => {
    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.rootdiv}>
                <div className={App_module.LeftDemo}>
                    <div className={styles.TextBlock_title}>专业留学作业代写</div>
                    <div className={styles.TextBlock_items}>
                        <li className={styles.LeftDemoItem}>职业计算机Developer， 10年大厂经验</li>
                        <li className={styles.LeftDemoItem}>美国研究生经历，深谙学校要求</li>
                        <li className={styles.LeftDemoItem}>分解进度交货， 确认满意后付款</li>
                        <li className={styles.LeftDemoItem}>本人亲力亲为，绝不转包</li>
                        <li className={styles.LeftDemoItem}>
                            热心提示，交作业前记得查剽窃工具plagiarismdetector.net
                        </li>
                    </div>
                </div>
            </div>
        </div>
    );
};
